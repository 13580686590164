// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
            production: false,
      // serverUrl: 'http://localhost:56860/',
     // serverUrl: 'https://qapmsapi.intigate.co.in/',
      serverUrl: 'https://pmsapi.intigate.co.in/',
 // privacyPolicyUrl: 'https://ctmstorage.blob.core.windows.net/ctmaboutus/CTM-Renewal-Cancellation-And-Refund-Policy.pdf',
 // stripePublicKey: 'pk_test_gImQrAn4jMqYVuePo4Zb4lA100FTbgbjfh'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
