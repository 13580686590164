import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-modal-popup',
  templateUrl: './feedback-modal-popup.component.html',
  styleUrls: ['./feedback-modal-popup.component.css']
})
export class FeedbackModalPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
