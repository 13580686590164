import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    // { path: '', redirectTo: '/', pathMatch: 'full' },

    // {
    //   path: 'dl',
    //   loadChildren: () => import('./shared/components/layouts/default-layout/default-layout.module').then(mod => mod.DefaultLayoutModule),
    // }

    {
      path:'',
      loadChildren: () => import('./shared/components/layouts/default-layout/default-layout.module').then(mod => mod.DefaultLayoutModule),
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes
  //   , {
  //   onSameUrlNavigation: 'reload'
  // }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
