import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule,ReactiveFormsModule }    from '@angular/forms';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbActiveModal,NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeedbackModalPopupComponent } from './shared/components/sub-components/sidebar/feedback-modal-popup/feedback-modal-popup.component';



//import { NumberDirective } from 'src/app/shared/components/helpers/numbers-only.directive';

@NgModule({
  declarations: [
    AppComponent,
    FeedbackModalPopupComponent
  //  NumberDirective
  ],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    AppRoutingModule,
    //FormsModule,
    // ReactiveFormsModule,
    // HttpClientModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    NgbActiveModal,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },
   

  ],
  entryComponents:[FeedbackModalPopupComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
